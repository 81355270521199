<template>
  <div>
    <div class="flow-list-wrap" v-if="flowList.length > 0">
        <div class="list-wrap" v-for="(item, index) in flowList" :key="index">
          <h2 class="title">{{ item.title }}</h2>
          <div class="flow-list">
            <div :class=" item2.active ? 'list-item active' : 'list-item'" v-for="(item2, index2) in item.lists" :key="index2" @click="selectItem(index, index2)">
              <div class="inner">
                <p class="num">{{item2.num}}GB</p>
                <p>{{ item2.price }}元/月</p>
                <div class="is-top" v-if="item2.isTop">推荐</div>
              </div>
            </div>
          </div>
        </div>
    </div>
      <div v-else class="no-data">
        <svg-icon icon-class="no_data" />
        <p class="text">没有任何结果！</p>
      </div>
    <div class="total-wrap" v-if="isTotal">
      <p>即日起至2021年05月31日</p>
      <p>月流量清零，本月剩余天数4</p>
      <p class="total">合计 <span class="price">{{ total }}</span>元</p>
    </div>
    <div class="btn-con" v-if="flowList.length > 0">
      <button type="button" class="btn">选择</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'package',
  data () {
    return {
      flowList: [],
      total: 0
    }
  },
  created () {
    this.isTotal = false
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  methods: {
    // 选择流量
    selectItem (index, index2) {
      if (this.flowList.length > 0) {
        for (let i = 0; i < this.flowList.length; i++) {
          for (let j = 0; j < this.flowList[i].lists.length; j++) {
            if (this.flowList[i].lists[j].active) {
              this.flowList[i].lists[j].active = false
            } else {
              this.isTotal = false
            }
          }
        }
        this.flowList[index].lists[index2].active = true
        const total = this.flowList[index].lists[index2].price
        this.isTotal = true
        this.total = total
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .flow-list-wrap{
    padding-top: 0.5rem;
    .list-wrap{
      margin-top:1.75rem;
      .title {
        padding: 0 1.25rem;
        font-size: 1rem;
        line-height: 1.3125rem;
        margin-bottom: .5rem;
      }
    }
    .flow-list{
      padding: 0 0.6875rem;
      margin-top: -1.125rem;
      display: flex;
      flex-wrap: wrap;
      .list-item{
        width: 33.333%;
        margin-top: 1.125rem;
        padding: 0 0.5625rem;
        box-sizing: border-box;
        &.active{
          .inner{
            &::before{
              content: '';
            }
          }
        }
        .inner{
          background-color: #fff;
          border-radius: 5px;
          padding: 0.875rem 0 1.125rem;
          box-sizing: border-box;
          text-align: center;
          font-size: 0.75rem;
          line-height: 1rem;
          color:#0176F7;
          position: relative;
          overflow: hidden;
          &::before{
            width: 100%;
            height: 100%;
            position: absolute;
            border:2px solid #0176F7;
            left: 0;
            top:0;
            box-sizing: border-box;
            border-radius: 5px;
          }
          .num{
            font-size: 1rem;
            line-height: 1.3125rem;
          }
          .is-top{
            height: 1.125rem;
            line-height: 1.125rem;
            position: absolute;
            left:-0.75rem;
            top:0.2rem;
            font-size: 0.75rem;
            background-color: #FF6B00;
            width: 3.25rem;
            text-align: center;
            color:#fff;
            z-index: 10;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .total-wrap{
    margin-top:2.5rem;
    padding: 0 1.125rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    text-align: right;
    color:#666;
    .total{
      margin-top:0.3125rem;
      color: #333;
      .price{
        font-size: 1.125rem;
        color: #ff6b00;
        font-weight: bold;
      }
    }
  }
  .btn-con{
    margin-top: 2.5rem;
    padding: 0 1.25rem;
  }
</style>
